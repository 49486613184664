import React, { lazy, useEffect, useState } from 'react'

import { getDataClient } from './services/api'
import './omni-credits-label.scss'

const OmniCreditsIcon = lazy(() => import('src/images/svg/icon-omni-credits'))

function OmniCreditsLabel() {
  const [omniCredits, setOmniCredits] = useState<number>()

  useEffect(() => {
    getDataClient().then((res) => {
      if (res) {
        setOmniCredits(res.valeTroca)
      }
    })
  }, [])

  return (
    <section className="omni-label-container">
      <>
        {omniCredits !== undefined && (
          <>
            <OmniCreditsIcon />
            {omniCredits > 0 ? (
              <h3>
                Você tem <strong>R$ {omniCredits}</strong> de CRÉDITOS para
                utilizar na sua compra! Eles estarão disponíveis no momento do
                pagamento.
              </h3>
            ) : (
              <h3>
                Você não tem CRÉDITOS para usar agora. Caso você tenha e não
                esteja aparecendo, entre em contato com a nossa Central de
                Atendimento
              </h3>
            )}
          </>
        )}
      </>
    </section>
  )
}

export default OmniCreditsLabel
