import type { FC } from 'react'
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  createContext,
} from 'react'
import axios from 'axios'
import { deepObjectCopy } from 'src/utils'
import getWishlistIndexById from 'src/utils/wishlist/getWishlistIndexById'
import type { ProductSummary_ProductFragment } from '@generated/graphql'
import type {
  ContextWishlistProductProps,
  WishlistType,
} from 'src/typings/wishlist'
import updateListWishlist from 'src/utils/wishlist/updateListWishlist'
import { waitRequest, changeWaitRequestUpdate } from 'src/utils/waitRequest'

import { API_ENDPOINTS } from './constants/apis'
import { useUserContext } from '../user-context'
import removeProductFromWishlistUtil from './utils/removeProductFromWishlistUtil'
import changeWishlistProductSizeUtil from './utils/changeWishlistProductSizeUtil'
import moveWishlistProductUtil from './utils/moveWishlistProductUtil'
import addProductToWishlistUtil from './utils/addProductToWishlistUtil'

const WishlistContext = createContext<WishlistContextProps>(
  {} as WishlistContextProps
)

export const WishlistProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [lists, setLists] = useState<WishlistType[]>([])
  const [currentWishlist, setCurrentWishlist] = useState<WishlistType>(
    {} as WishlistType
  )

  const { user } = useUserContext()
  const { wishlistGet, wishlistAdd, wishlistDelete } = API_ENDPOINTS

  const createWishlist = async (name: string, isDefault = false) => {
    try {
      const newList = { name, productList: [], isDefault }
      const { data } = await axios.post(wishlistAdd, {
        newList,
      })

      setLists([...lists, { id: data?.DocumentId, ...newList }])

      return true
    } catch (error) {
      console.error(`Error wishlist: ${error}`)
    }

    return false
  }

  const getLists = useCallback(async () => {
    if (!user?.id || lists.length) {
      return false
    }

    try {
      const { data: response } = await axios.post(wishlistGet)

      if (response) {
        const { data, message } = response

        if (!data?.length) {
          !message && (await createWishlist('Meus Favoritos', true))

          return true
        }

        setLists(data)
      }
    } catch (error) {
      console.error(`Error wishlist: ${error}`)
    }

    return false
  }, [user])

  const deleteWishlist = async () => {
    try {
      const listsCopy: WishlistType[] = deepObjectCopy(lists)
      const updatedList = listsCopy?.filter(
        (wishlist) => wishlist.id !== currentWishlist.id
      )

      await axios.delete(wishlistDelete, {
        data: {
          id: currentWishlist.id,
        },
      })

      setLists(updatedList)

      return true
    } catch (error) {
      console.error(`Error wishlist: ${error}`)
    }

    return false
  }

  const changeNameWishlist = async (newName: string) => {
    const currentWishlistCopy: WishlistType = deepObjectCopy(currentWishlist)
    const listsCopy: WishlistType[] = deepObjectCopy(lists)
    const wishlistId = getWishlistIndexById(currentWishlistCopy, lists)

    currentWishlistCopy.name = newName

    await updateListWishlist(currentWishlistCopy)

    listsCopy[wishlistId] = currentWishlistCopy

    setLists(listsCopy)
  }

  const deleteProduct = async (productId: string) => {
    try {
      await waitRequest()
      changeWaitRequestUpdate(true)

      const listsCopy: WishlistType[] = deepObjectCopy(lists)
      const currentWishlistCopy: WishlistType = deepObjectCopy(currentWishlist)
      const currentWishlistIndex = getWishlistIndexById(currentWishlist, lists)
      const wishlistWithProductDeleted =
        currentWishlistCopy?.productList?.filter(
          (product) => product?.productId !== productId
        )

      currentWishlistCopy.productList = wishlistWithProductDeleted
      listsCopy[currentWishlistIndex].productList = wishlistWithProductDeleted

      setCurrentWishlist(currentWishlistCopy)
      setLists(listsCopy)

      await updateListWishlist(listsCopy[currentWishlistIndex])

      return listsCopy
    } catch (error) {
      console.error(`Error wishlist: ${error}`)
    } finally {
      changeWaitRequestUpdate(false)
    }

    return []
  }

  const updateCurrentWishlist = (list?: WishlistType) => {
    list ? setCurrentWishlist(list) : setCurrentWishlist({} as WishlistType)
  }

  const moveWishlistProduct = async (
    productIndex: number,
    wishlistIndex: number
  ) => {
    try {
      await moveWishlistProductUtil({
        productIndex,
        wishlistIndex,
        setLists,
        currentWishlist,
        deleteProduct,
      })
    } catch (error) {
      console.error(`Error wishlist: ${error}`)
    }
  }

  const addProductToWishlist = async (
    wishlistProduct: ContextWishlistProductProps
  ) =>
    addProductToWishlistUtil({
      products: wishlistProduct,
      lists,
      setLists,
    })

  const removeProductFromWishlist = async (
    products: ProductSummary_ProductFragment[],
    index?: number
  ) =>
    removeProductFromWishlistUtil({
      products,
      index,
      lists,
      setLists,
    })

  const changeWishlistProductSize = async (
    productIndex: number,
    newSize: string,
    productSku: string
  ) =>
    changeWishlistProductSizeUtil({
      productIndex,
      newSize,
      currentWishlist,
      lists,
      setLists,
      productSku,
    })

  useEffect(() => {
    getLists()
  }, [getLists])

  return (
    <WishlistContext.Provider
      value={{
        lists,
        currentWishlist,
        getLists,
        createWishlist,
        deleteWishlist,
        updateCurrentWishlist,
        changeNameWishlist,
        deleteProduct,
        moveWishlistProduct,
        addProductToWishlist,
        removeProductFromWishlist,
        changeWishlistProductSize,
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}

export const useWishlistContext = () => {
  return useContext(WishlistContext)
}
