import type {
  IRemoveProductFromWishlistUtil,
  WishlistType,
} from 'src/typings/wishlist'
import { deepObjectCopy } from 'src/utils'
import getWishlistIndexById from 'src/utils/wishlist/getWishlistIndexById'
import postMessageWishlist from 'src/utils/wishlist/events/postMessageWishlist'
import newWishlistAndDataLayer from 'src/utils/wishlist/newWishlistAndDataLayer'
import updateListWishlist from 'src/utils/wishlist/updateListWishlist'

async function removeProductFromWishlistUtil({
  products,
  index,
  lists,
  setLists,
}: IRemoveProductFromWishlistUtil) {
  if (products?.length) {
    const listsCopy = deepObjectCopy(lists) as WishlistType[]
    const listWishlistIndex = []

    for (const productItem of products) {
      const {
        nameCategory,
        productRefId,
        updatedProductList,
        variant,
        wishlist,
      } = newWishlistAndDataLayer(productItem, listsCopy)

      if (wishlist && updatedProductList) {
        const wishlistIndex = getWishlistIndexById(wishlist, lists)

        listWishlistIndex.push(wishlistIndex)
        listsCopy[wishlistIndex].productList = updatedProductList

        postMessageWishlist(
          productItem,
          {
            nameCategory,
            productRefId,
            variant,
            index,
          },
          'store:remove_wish_list'
        )
      }
    }

    const updateWishlist = listWishlistIndex.map((indexWishlist) =>
      updateListWishlist(listsCopy[indexWishlist])
    )

    await Promise.all(updateWishlist)

    setLists(listsCopy)

    return true
  }

  return false
}

export default removeProductFromWishlistUtil
