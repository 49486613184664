import React from 'react'

import GoogleTagManager from './GoogleTagManager'
import Clarity from './Clarity'
import VTEX from './vtex'
import Ga from './Ga'
import OneTrust from './OneTrust'
import APMAzure from './APMAzure'

function ThirdPartyScripts() {
  return (
    <>
      <GoogleTagManager />
      <Clarity />
      <VTEX />
      <Ga />
      <OneTrust />
      <APMAzure />
    </>
  )
}

export default ThirdPartyScripts
