export function setCookie(name: string, value: string, duration: number) {
  const expires: Date = new Date()

  expires.setDate(new Date().getDate() + duration)
  const cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires.toUTCString()}; path=/`

  document.cookie = cookie
}

export function getCookie(name: string) {
  const cookies = document.cookie
  const prefix = `${name}=`
  let begin = cookies.indexOf(`; ${prefix}`)

  if (begin === -1) {
    begin = cookies.indexOf(prefix)

    if (begin !== 0) {
      return null
    }
  } else {
    begin += 2
  }

  let end = cookies.indexOf(';', begin)

  if (end === -1) {
    end = cookies.length
  }

  return decodeURIComponent(cookies.substring(begin + prefix.length, end))
}

export function deleteCookie(name: string) {
  if (getCookie(name)) {
    document.cookie = `${name}=` + `; expires=Thu, 01-Jan-70 00:00:01 GMT`
  }
}
