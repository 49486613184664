import type {
  IAddProductToWishlistUtil,
  WishlistType,
} from 'src/typings/wishlist'
import { deepObjectCopy } from 'src/utils'
import postMessageWishlist from 'src/utils/wishlist/events/postMessageWishlist'
import getDataWishlistForDataLayer from 'src/utils/wishlist/getDataWishlistForDataLayer'
import updateListWishlist from 'src/utils/wishlist/updateListWishlist'

async function addProductToWishlistUtil({
  products,
  lists,
  setLists,
}: IAddProductToWishlistUtil) {
  if (products.length) {
    const listsCopy = deepObjectCopy(lists) as WishlistType[]
    const listWishlistIndex = []

    for (const item of products) {
      const productIdItem = item?.product?.sku ?? item?.product?.id

      listWishlistIndex.push(item.chosenOption)
      listsCopy[item?.chosenOption]?.productList?.push({
        productId: productIdItem,
        productSize: item?.productSize,
      })

      const dataProductForDataLayer = getDataWishlistForDataLayer(item?.product)

      postMessageWishlist(
        item?.product,
        {
          ...dataProductForDataLayer,
          variant: undefined,
        },
        'store:add_wish_list'
      )
    }

    const updateWishlist = listWishlistIndex.map((indexWishlist) =>
      updateListWishlist(listsCopy[indexWishlist])
    )

    await Promise.all(updateWishlist)

    setLists(listsCopy)

    return true
  }

  return false
}

export default addProductToWishlistUtil
